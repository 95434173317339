/*! GS1 Web Guidelines */
// ============================================================================
@use "theme/variables" as gs1-vars;
@use "../node_modules/rfs/scss" as rfs with (
  $rfs-base-value: gs1-vars.$rfs-base-value,
  $rfs-factor: gs1-vars.$rfs-factor,
  $rfs-breakpoint: gs1-vars.$rfs-breakpoint
);
@use "theme/colors";
@use "theme/normalize-custom";
@use "theme/type";
@use "theme/utilities";
@use "theme/buttons";
@use "theme/headerFooter";

body {
  overflow-x: hidden;
}
